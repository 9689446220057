import { useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AOS from "aos";
import NavScrollTop from "./components/NavScrollTop";
import TermsConditions from "./pages/termsConditions/TermsConditions";
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy";
 import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
const HomeOne = lazy(() => import("./pages/HomeOne"));
const HomeTwo = lazy(() => import("./pages/HomeTwo"));
const HomeThree = lazy(() => import("./pages/HomeThree"));
const About = lazy(() => import("./pages/About"));
const Service = lazy(() => import("./pages/Service"));
const Work = lazy(() => import("./pages/Work"));
const WorkDetails = lazy(() => import("./pages/WorkDetails"));
const BlogGrid = lazy(() => import("./pages/BlogGrid"));
const BlogClassic = lazy(() => import("./pages/BlogClassic"));
const BlogDetails = lazy(() => import("./pages/BlogDetails"));
const BlogCategories = lazy(() => import("./pages/BlogCategories"));
const BlogTag = lazy(() => import("./pages/BlogTag"));
const Contact = lazy(() => import("./pages/Contact"));



function App() {
  useEffect(() => {
    AOS.init({
      offset: 80,
      duration: 1000,
      once: true,
      easing: "ease",
    });
    AOS.refresh();
  }, []);
  return (
    <Router>
      <NavScrollTop>
        <Suspense fallback={<div />}>
          <Routes>
            <Route
              path={`${process.env.PUBLIC_URL + "/"}`}
              element={<HomeOne />}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/about"}`}
              element={<About />}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/service"}`}
              element={<Service />}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/work"}`}
              element={<Work />}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/work-details/:id"}`}
              element={<WorkDetails />}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/blog-grid"}`}
              element={<BlogGrid />}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/blog-classic"}`}
              element={<BlogClassic />}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/tag/:slug"}`}
              element={<BlogTag />}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/category/:slug"}`}
              element={<BlogCategories />}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/blog-details/:id"}`}
              element={<BlogDetails />}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/contact"}`}
              element={<Contact />}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/privacy-policy"}`}
              element={<PrivacyPolicy />}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/terms-conditions"}`}
              element={<TermsConditions />}
            />

            <Route
              path={`${process.env.PUBLIC_URL + "/contact"}`}
              element={<Contact />}
            />
          </Routes>
        </Suspense>
      </NavScrollTop>
    </Router>
   
  );
}
// {
//   console.log(process.env, "process.env");
// }

export default App;
